import React from 'react'

export const SocialCardText = ({ text, className = "", setText }) => {
	const ref = React.useRef()
	
	return (
		<div className={`form-group ${className}`}>
			<label>Text (use <a href="https://www.markdownguide.org/cheat-sheet/" target="_blank">Markdown</a> for formatting)</label>
			<div className="grow-wrap" ref={ref}>
				<textarea
					value={text}
					onChange={(e) => {
						setText(e.target.value)

						if (!ref.current) return
						ref.current.dataset.replicatedValue = e.target.value
					}}
					className="form-control"
				/>
			</div>
		</div>
	)
}
