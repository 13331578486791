import React from 'react'

export const SocialCardFontFamily = ({ fontFamily, className = "", setFontFamily }) => {
	return (
		<div className={`form-group ${className}`}>
			<label>Font family</label>
			<select
				value={fontFamily}
				onChange={(e) => setFontFamily(e.target.value)}
				className="form-control"
			>
				<option value="serif">Serif</option>
				<option value="sans-serif">Sans-serif</option>
				<option value="MetricHPE">MetricHPE</option>
			</select>
		</div>
	)
}
