import React from "react"

export const SocialCardLogo = ({ className = "", logo, setLogo, setLogoWidth }) => {
	const ref = React.useRef()
	
	const handleFileChange = (e) => {
		const reader = new FileReader()

		reader.onload = (readerEvent) => {
			if (readerEvent.target) {
				setLogo(readerEvent.target.result)
			}
		}
		
		reader.readAsDataURL(e.target.files[0])
	}

	React.useEffect(() => {
		if (logo !== "") {
			setLogoWidth(ref.current.width)
		} else {
			setLogoWidth(0)
		}
	}, [ logo ])

	return (
		<div className={`form-group ${className}`}>
			<label>Logo</label>
			<input
				type="file"
				onChange={handleFileChange}
				className="form-control"
			/>
			{logo !== "" ? (
				<img
					src={logo}
					ref={ref}
					className="mt-4 mb-2 d-block"
				/>
			) : null}
			<button
				className="btn btn-danger mt-2"
				onClick={() => setLogo("")}
			>Remove logo</button>
		</div>
	)
}
