import React from 'react'

export const SocialCardTextColor = ({ textColor, className = "", setTextColor }) => {
	return (
		<div className={`form-group ${className}`}>
			<label>Text color</label>
			<input
				type="color"
				value={textColor}
				onChange={(e) => setTextColor(e.target.value)}
				className="form-control"
			/>
		</div>
	)
}
