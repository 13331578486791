import React from 'react'

export const SocialCardBackground = ({ className = "", setBackground }) => {
	const handleFileChange = (e) => {
		const reader = new FileReader()

		reader.onload = (readerEvent) => {
			if (readerEvent.target) {
				setBackground(readerEvent.target.result)
			}
		}

		reader.readAsDataURL(e.target.files[0])
	}
	
	return (
		<div className={`form-group ${className}`}>
			<label>Background</label>
			<input
				type="file"
				onChange={handleFileChange}
				className="form-control"
			/>
			<button
				className="btn btn-danger mt-2"
				onClick={() => setBackground("")}
			>Remove background</button>
		</div>
	)
}
