import React from 'react'

export const SocialCardPadding = ({ padding, className = "", setPadding }) => {
	return (
		<div className={`form-group ${className}`}>
			<label>Padding</label>
			<input
				type="number"
				value={padding}
				onChange={(e) => setPadding(e.target.value)}
				className="form-control"
			/>
		</div>
	)
}
