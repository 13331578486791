import React from 'react'
import html2canvas from 'html2canvas'
import marked from 'marked'

export const SocialCardRenderer = (props) => {
	const {
		alignment,
		justify,
		order,
		size,
		background,
		logo,
		logoWidth,
		padding,
		spacing,
		fontSize,
		fontFamily,
		lineHeight,
		textColor,
		text,
		buttonBase64
	} = props

	const styles = {
		width: `${size[0]}px`,
		height: `${size[1]}px`,
		border: '1px solid #000000',
		padding: padding,
		display: 'flex',
		flexDirection: 'column' as 'column',
		gap: `${spacing}px`,
		alignItems: alignment,
		justifyContent: justify,
		overflow: "hidden"
	}

	const wrapper = React.useRef()

	const backgroundExists = () => background !== ""
	const logoExists = () => logo !== ""
	const textExists = () => text !== ""
	const buttonExists = () => buttonBase64 !== ""

	if (backgroundExists()) {
		styles["background"] = `url('${background}')`
		styles["background-size"] = "cover"
		styles["background-position"] = "center center"
	}

	const renderLogo = () => {
		return (
			<div>
				<img src={logo} width={logoWidth} />
			</div>
		)
	}

	const renderText = () => {
		let textAlign: "left" | "center" | "right" = "left" as "left"

		if (alignment === "center") textAlign = "center" as "center"
		if (alignment === "end") textAlign = "right" as "right"
		
		return (
			<div
				style={{
					fontSize: `${fontSize}px`,
					lineHeight: `${lineHeight}px`,
					fontFamily: fontFamily,
					color: textColor,
					textAlign: textAlign
				}}	
				dangerouslySetInnerHTML={{ __html: marked.parse(text) }}
			/>
		)
	}

	const renderButton = () => {
		if (buttonBase64 !== "") {
			return (
				<div>
					<img src={`data:image/png;base64, ${buttonBase64}`} crossOrigin="anonymous" />
				</div>
			)
		}

		return null
	}

	const render = () => {
		return order.map(component => {
			switch (component) {
				case 'logo':
					if (!logoExists()) return null
					return renderLogo()
				case 'text':
					if (!textExists()) return null
					return renderText()
				case 'button':
					if (!buttonExists()) return null
					return renderButton()
			}
		})
	}

	const generateFileName = () => {
		return `${order.join("-")}_${alignment}_${justify}`
	}

	const savePng = () => {
		html2canvas(wrapper.current).then(canvas => {
			var link = document.createElement('a')
			link.setAttribute('download', `${generateFileName()}.png`)
			link.setAttribute('href', canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"))
			link.click()
		})
	}

	const saveJpeg = () => {
		html2canvas(wrapper.current).then(canvas => {
			var link = document.createElement('a')
			link.setAttribute('download', `${generateFileName()}.jpg`)
			link.setAttribute('href', canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream"))
			link.click()
		})
	}

	return (
		<div>
			<div
				style={styles}
				ref={wrapper}
			>
				{render()}
			</div>
			<div className="btn-group mt-2">
				<button className="btn btn-primary" onClick={savePng}>Save PNG</button>
				<button className="btn btn-secondary" onClick={saveJpeg}>Save JPEG</button>
			</div>
		</div>
	)
}
