import React from 'react'

export const SocialCardSpacing = ({ spacing, className = "", setSpacing }) => {
	return (
		<div className={`form-group ${className}`}>
			<label>Spacing between items</label>
			<input
				type="number"
				value={spacing}
				onChange={(e) => setSpacing(e.target.value)}
				className="form-control"
			/>
		</div>
	)
}
