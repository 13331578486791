import React from 'react'
import useFetch from 'use-http'

const MR_BUTTON_TOOL_URL = 'https://buttons.utils.mreach.com/brands'

export const SocialCardButtonBrand = ({ buttonBrand, className = "", setButtonBrand }) => {
	const [ list, setList ] = React.useState()
	
	const { get: getBrands } = useFetch(MR_BUTTON_TOOL_URL, {
		headers: {
			'Accept': 'application/json'
		}
	})

	React.useEffect(() => {
		const run = async () => {
			const { brands } = await getBrands()

			const runStyles = async (brand) => {
				const { styles } = await getBrands(`/${brand.id}`)
				brand.styles = styles
			}
			
			brands.map(async(brand) => {
				await runStyles(brand)
			})

			setList(brands)
		}

		run()
	}, [])

	const renderList = () => {
		if (list !== undefined) {
			return list.map((brand) => {
				return (
					<optgroup
						label={brand.name}
						key={`brand-${brand.id}`}
					>
						{brand.styles?.map(style => {
							return (
								<option
									value={`${MR_BUTTON_TOOL_URL}/${brand.id}/styles/${style.id}`}
									key={`style-${style.id}`}
								>{style.title}</option>
							)
						})}
					</optgroup>
				)
			})
		}

		return null
	}

	return (
		<div className={`form-group ${className}`}>
			<label>Button brand</label>
			<select
				value={buttonBrand}
				onChange={(e) => setButtonBrand(e.target.value)}
				className="form-control"
			>
				<option value=""></option>
				{renderList()}
			</select>
		</div>
	)
}
