import React from 'react'

export const SocialCardSize = ({ size, className = "", setSize }) => {
	const handleSizeChange = (e) => {
		setSize(e.target.value.split("x").map(i => parseInt(i)))
	}
	
	return (
		<div className={`form-group ${className}`}>
			<label>Canvas size</label>
			<select
				onChange={handleSizeChange}
				value={size.join("x")}
				className="form-control"
			>
				<option value="1200x628">1200x628</option>
				<option value="1200x675">1200x675</option>
			</select>
		</div>
	)
}
