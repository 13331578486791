import React from "react"

export const SocialCardLogoWidth = ({ className = "", logoWidth, setLogoWidth }) => {
	return (
		<div className={`form-group ${className}`}>
			<label>Logo width</label>
			<input
				type="number"
				onChange={(e) => setLogoWidth(e.target.value)}
				value={logoWidth}
				className="form-control"
			/>
		</div>
	)
}
