import React from 'react'

import { SocialCardSize } from './components/SocialCardSize'
import { SocialCardPadding } from './components/SocialCardPadding'
import { SocialCardBackground } from './components/SocialCardBackground'
import { SocialCardLogo } from './components/SocialCardLogo'
import { SocialCardFontSize } from './components/SocialCardFontSize'
import { SocialCardFontFamily } from './components/SocialCardFontFamily'
import { SocialCardLineHeight } from './components/SocialCardLineHeight'
import { SocialCardTextColor } from './components/SocialCardTextColor'
import { SocialCardText } from './components/SocialCardText'
import { SocialCardButtonBrand } from './components/SocialCardButtonBrand'
import { SocialCardButtonText } from './components/SocialCardButtonText'
import { SocialCardRenderer } from './components/SocialCardRenderer'
import { SocialCardSpacing } from './components/SocialCardSpacing'
import { SocialCardLogoWidth } from './components/SocialCardLogoWidth'

export const SocialCardBuilder = () => {
	// Canvas size
	const [ size, setSize ] = React.useState([1200, 628])
	// Padding
	const [ padding, setPadding ] = React.useState(80)
	// Spacing between items
	const [ spacing, setSpacing ] = React.useState(20)
	// Background
	const [ background, setBackground ] = React.useState("")
	// Logo
	const [ logo, setLogo ] = React.useState("")
	const [ logoWidth, setLogoWidth ] = React.useState(0)
	// Text
	const [ fontSize, setFontSize ] = React.useState(48)
	const [ fontFamily, setFontFamily ] = React.useState("serif")
	const [ lineHeight, setLineHeight ] = React.useState(52)
	const [ textColor, setTextColor ] = React.useState("#000000")
	const [ text, setText ] = React.useState("")
	// Button
	const [ buttonBrand, setButtonBrand ] = React.useState("")
	const [ buttonText, setButtonText ] = React.useState("")
	const [ buttonBase64, setButtonBase64 ] = React.useState("")

	// Props
	const socialCardRendererSharedProps = {
		size,
		background,
		logo,
		logoWidth,
		padding,
		spacing,
		fontSize,
		fontFamily,
		lineHeight,
		textColor,
		text,
		buttonBase64
	}

	React.useEffect(() => {
		const run = async () => {
			fetch(`${buttonBrand}/button?preview=true&text=${buttonText}&base64=true`)
				.then(response => response.text())
				.then(text => {
					setButtonBase64(text)
				})
		}

		if (buttonText !== "" && buttonBrand !== "") {
			run()
		}
	}, [ buttonText, buttonBrand ])

	const tabs = [
		["one", "Logo, text, button"],
		["two", "Logo, button, text"],
		["three", "Button, logo, text"],
		["four", "Button, text, logo"],
		["five", "Text, button, logo"],
		["six", "Text, logo, button"]
	]

	const alignments = ["start", "center", "end"]
	const justifications = ["top", "space-between", "center", "end"]

	return (
		<div className="pt-4 pb-4">
			<div className="container">
				<h1>Social Card Builder</h1>
			</div>
			<div className="container py-4 px-4 my-4 bg-light border rounded">
				<h2>Config</h2>
				<div className="accordion">
					<div className="accordion-item">
						<h2 className="accordion-header" id="canvas">
							<button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
								Canvas
							</button>
						</h2>
						<div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="canvas" data-bs-parent="#accordionExample">
							<div className="accordion-body">
								<SocialCardSize size={size} setSize={setSize} className="mb-4" />
								<SocialCardPadding padding={padding} setPadding={setPadding} className="mb-4" />
								<SocialCardSpacing spacing={spacing} setSpacing={setSpacing} className="mb-4" />
								<SocialCardBackground setBackground={setBackground} />
							</div>
						</div>
					</div>
					<div className="accordion-item">
						<h2 className="accordion-header" id="text">
							<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
								Text
							</button>
						</h2>
						<div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="text" data-bs-parent="#accordionExample">
							<div className="accordion-body">
								<SocialCardFontSize fontSize={fontSize} setFontSize={setFontSize} className="mb-4" />
								<SocialCardFontFamily fontFamily={fontFamily} setFontFamily={setFontFamily} className="mb-4" />
								<SocialCardLineHeight lineHeight={lineHeight} setLineHeight={setLineHeight} className="mb-4" />
								<SocialCardTextColor textColor={textColor} setTextColor={setTextColor} className="mb-4" />
								<SocialCardText text={text} setText={setText} />
							</div>
						</div>
					</div>
					<div className="accordion-item">
						<h2 className="accordion-header" id="brand">
							<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
								Brand
							</button>
						</h2>
						<div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="brand" data-bs-parent="#accordionExample">
							<div className="accordion-body">
								<SocialCardLogo logo={logo} setLogo={setLogo} setLogoWidth={setLogoWidth} className="mb-4" />
								<SocialCardLogoWidth logoWidth={logoWidth} setLogoWidth={setLogoWidth} className="mb-4" />
								<SocialCardButtonBrand buttonBrand={buttonBrand} setButtonBrand={setButtonBrand} className="mb-4" />
								<SocialCardButtonText buttonText={buttonText} setButtonText={setButtonText} />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="container mb-4">
				<h2>Layouts</h2>

				<ul className="nav nav-tabs" id="myTab" role="tablist">
					{tabs.map((tab, index) => {
						const label = tab[0]
						const name = tab[1]

						return (
							<li className="nav-item" role="presentation" key={`nav-item-${index}`}>
								<button className={`nav-link ${index === 0 ? "active" : ""}`} id={`${label}-tab`} data-bs-toggle="tab" data-bs-target={`#${label}`} type="button" role="tab" aria-controls={label} aria-selected="true">{name}</button>
							</li>
						)
					})}
				</ul>
			</div>

			<div className="container-fluid">
				<div className="tab-content" id="myTabContent">
					{tabs.map((tab, index) => {
						const label = tab[0]
						const order = tab[1].split(",").map(ord => ord.toLowerCase().trim())

						return (
							<div
								className={`tab-pane fade ${index === 0 ? "show active" : ""}`}
								id={label}
								role="tabpanel"
								aria-labelledby={`${label}-tab`}
								key={`tab-pane-${index}`}
							>
								{justifications.map((justification, justificationIndex) => (
									<div className="row mb-4" key={`row-${justificationIndex}`}>
										{alignments.map((alignment, alignmentIndex) => (
											<div className="col" key={`col-${alignmentIndex}`}>
												<small>{alignment}, {justification}</small>
												<SocialCardRenderer
													alignment={alignment}
													justify={justification}
													order={order}
													{...socialCardRendererSharedProps}
												/>
											</div>
										))}
									</div>
								))}
							</div>
						)
					})}
				</div>
			</div>
		</div>
	)
}
