import React from 'react'

export const SocialCardButtonText = ({ buttonText, className = "", setButtonText }) => {
	return (
		<div className={`form-group ${className}`}>
			<label>Button text</label>
			<input
				type="text"
				value={buttonText}
				onChange={(e) => setButtonText(e.target.value)}
				className="form-control"
			/>
		</div>
	)
}
