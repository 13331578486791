import React from 'react'

export const SocialCardFontSize = ({ fontSize, className = "", setFontSize }) => {
	return (
		<div className={`form-group ${className}`}>
			<label>Font size</label>
			<input
				type="number"
				value={fontSize}
				onChange={(e) => setFontSize(e.target.value)}
				className="form-control"
			/>
		</div>
	)
}
